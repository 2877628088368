.footer-container{
    position: relative;
}

.footer-container>hr{
    border : 1px solid var(--orange)
}

.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
}

.social-links{
    display: flex;
    gap: 4rem;
}

.social-links>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-f>img{
    width: 10rem;
}

